<template>
  <v-container
    class="dash pl-10"
    :style="{
      'padding-right': $store.state.filtersDrawerExpanded
        ? !$vuetify.breakpoint.smAndDown
          ? '320px'
          : '20px'
        : '12px',
    }"
    fluid
  >
    <core-app-bar />
    <v-row no-gutters>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          dark
          color="blue-grey lighten-2"
          class="d-print-none text-capitalize"
          @click="handleToggleFilters(true)"
        >
          <v-icon> mdi-filter-outline</v-icon>
          {{ $t('filters') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-print-none text-capitalize ml-2"
          @click="print"
        >
          <v-icon left>mdi-printer-outline</v-icon>
          {{ $t('print') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <visualization
          card
          type="bar"
          query="psp_evolution_des_transactions_base_mensuelle"
          :translations="translations"
          :project="$store.getters.merchantId"
          :title="$t('evolutionOfMonthlyTransactions')"
          :options="{
            noData: {
              text: $t('emptyData'),
            },
            xAxes: ['Date'],
            xaxis: {
              reverse: true,
            },
            yAxes: ['Transaction'],
            colors: paletteChart,
            dataLabels: {
              style: {
                colors: ['#000000', '#000000', '#000000'],
              },
            },
          }"
          :external-filters-values="specificFilters"
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="auto"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col lg="6" class="py-0">
        <v-row>
          <v-col lg="6">
            <v-switch
              :label="$t('displayAcceptanceToggleLabel')"
              v-model="showSelectCurrency"
              v-on:change="handleShowCurrency"
            ></v-switch>
          </v-col>
          <v-col lg="6" v-if="showSelectCurrency">
            <v-select
              :label="$t('currency')"
              v-model="currency"
              :items="currencies"
              v-on:change="handleSelectCurrency"
            ></v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      :class="{
        'd-flex': showSelectCurrency,
        'align-center': showSelectCurrency,
      }"
    >
      <v-col cols="12" lg="6" md="12">
        <visualization
          card
          type="table"
          query="psp_overview_tableau"
          :translations="translations"
          :project="$store.getters.merchantId"
          :title="$t('acceptanceInDomesticCurrency')"
          :external-filters-values="globalFilters"
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="auto"
        />
      </v-col>
      <v-col cols="12" lg="6" md="12" v-if="showSelectCurrency">
        <div
          class="d-flex justify-center flex-column align-center"
          v-if="!currency"
        >
          <v-icon size="100" class="mb-4 grey--text text--lighten-1">
            mdi-swap-horizontal
          </v-icon>
          <span class="text-center text-h5 grey--text text--lighten-1">
            {{ $t('chooseCurrencyFilter') }}
          </span>
        </div>
        <visualization
          card
          type="table"
          query="psp_overview_tableau_settlement"
          :translations="translations"
          :project="$store.getters.merchantId"
          :title="$t('acceptanceCurrency')"
          :external-filters-values="settlementCurrencyFilters"
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="auto"
          v-else
        />
      </v-col>
      <v-col cols="12" lg="6" md="12">
        <visualization
          ref="grapheGateway"
          card
          type="pie"
          query="psp_overview_graph"
          :project="$store.getters.merchantId"
          :title="$t('distributionOfVolumesByGateway')"
          :options="{
            noData: {
              text: $t('emptyData'),
            },
            xAxes: ['organism'],
            yAxes: ['count'],
            colors: paletteChart,
          }"
          :external-filters-values="globalFilters"
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="auto"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <div class="divider-title">
          {{ $t('netAcceptanceRatePerGateway') }}
        </div>
      </v-col>
      <v-col cols="12" lg="6" md="12">
        <visualization
          card
          type="table"
          :title="$t('netAcceptanceRatePerGateway')"
          query="psp_taux_acceptation_net_par_gateway_tableau"
          :translations="translations"
          :project="$store.getters.merchantId"
          :external-filters-values="globalFilters"
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="auto"
        />
      </v-col>
      <v-col cols="12" lg="6" md="12">
        <visualization
          card
          type="bar"
          query="psp_taux_acceptation_net_par_gateway_graph"
          :translations="translations"
          :project="$store.getters.merchantId"
          :title="$t('netAcceptanceRatePerGateway')"
          :options="{
            noData: {
              text: $t('emptyData'),
            },
            xAxes: ['Organism'],
            yAxes: [$t('rate')],
            colors: paletteChart,
            dataLabels: {
              formatter: percentFormatter,
            },
            yaxis: {
              max: 100,
              tickAmount: 5,
              labels: {
                show: true,
              },
            },
          }"
          :external-filters-values="globalFilters"
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="auto"
        />
      </v-col>
    </v-row>
    <div class="divider-title">
      {{ $t('distributionOfVolumesBySalesChannel') }}
    </div>
    <v-row>
      <v-col cols="12" lg="6" md="12">
        <visualization
          card
          type="table"
          query="psp_repartition_des_transactions_par_canal_de_vente_tableau"
          :translations="translations"
          :project="$store.getters.merchantId"
          :title="$t('volumesBySalesChannel')"
          :external-filters-values="globalFilters"
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="auto"
        />
      </v-col>
      <v-col cols="12" lg="6" md="12">
        <visualization
          card
          type="pie"
          query="psp_repartition_des_transactions_par_canal_de_vente_graph"
          :project="$store.getters.merchantId"
          :title="$t('distributionOfTransactionsByChannel')"
          :options="{
            noData: {
              text: $t('emptyData'),
            },
            xAxes: ['Canal de vente'],
            yAxes: ['Nombre de Statut de Transaction'],
            colors: paletteChart,
          }"
          :external-filters-values="globalFilters"
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="auto"
        />
      </v-col>
    </v-row>
    <div class="divider-title">
      {{ $t('distributionOfVolumesByTypeOfPaymentMethod') }}
    </div>
    <v-row>
      <v-col cols="12" lg="6" md="12">
        <visualization
          card
          type="table"
          :title="$t('distributionOfTransactionsByTypeOfPaymentMethod')"
          query="psp_repartition_transactions_par_type_de_mop"
          :translations="translations"
          :project="$store.getters.merchantId"
          :external-filters-values="globalFilters"
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="auto"
        />
      </v-col>
      <v-col cols="12" lg="6" md="12">
        <visualization
          card
          type="pie"
          query="psp_repartition_transactions_par_type_de_mop"
          :project="$store.getters.merchantId"
          :title="$t('distributionOfTransactionsByTypeOfPaymentMethod')"
          :options="{
            noData: {
              text: $t('emptyData'),
            },
            xAxes: ['Type de méthode de paiement'],
            yAxes: ['Volume'],
            colors: paletteChart,
          }"
          :external-filters-values="globalFilters"
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="auto"
        />
      </v-col>
    </v-row>
    <div class="divider-title">
      {{ $t('distributionOfVolumesByTypeOfCard') }}
    </div>
    <v-row>
      <v-col cols="12" lg="6" md="12">
        <visualization
          card
          type="table"
          :title="$t('distributionOfVolumesByBrand')"
          query="psp_repartition_par_type_de_carte_tableau"
          :translations="translations"
          :project="$store.getters.merchantId"
          :external-filters-values="globalFilters"
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="auto"
        />
      </v-col>
      <v-col cols="12" lg="6" md="12">
        <visualization
          card
          type="pie"
          query="psp_repartition_par_type_de_carte_graph"
          :project="$store.getters.merchantId"
          :title="$t('distributionOfVolumesByBrand')"
          :options="{
            noData: {
              text: $t('emptyData'),
            },
            xAxes: ['Brand'],
            yAxes: ['Volume'],
            colors: paletteChart,
          }"
          :external-filters-values="globalFilters"
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="auto"
        />
      </v-col>
    </v-row>
    <div class="divider-title">
      {{ $t('distributionOfVolumesByCountries') }} (Top 10)
    </div>
    <v-row>
      <v-col cols="12" lg="6" md="12">
        <visualization
          card
          type="table"
          :title="$t('distributionOfTransactionsByCountries')"
          query="psp_repartition_volumes_par_pays_top10"
          :translations="translations"
          :project="$store.getters.merchantId"
          :external-filters-values="globalFilters"
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="auto"
        />
      </v-col>
      <v-col cols="12" lg="6" md="12">
        <visualization
          card
          type="bar"
          query="psp_repartition_volumes_par_pays_top10"
          :translations="translations"
          :project="$store.getters.merchantId"
          :title="$t('distributionOfTransactionsByCountries')"
          :options="{
            noData: {
              text: $t('emptyData'),
            },
            xAxes: [$t('country')],
            yAxes: ['Volume'],
            colors: paletteChart,
          }"
          :external-filters-values="globalFilters"
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="auto"
        />
      </v-col>
    </v-row>
    <div class="divider-title">
      {{ $t('distributionOfVolumesByCountryOfIssue') }} (Top 10)
    </div>
    <v-row>
      <v-col cols="12" lg="6" md="12">
        <visualization
          card
          type="table"
          :title="$t('distributionByCountryOfIssueOfTheCard')"
          query="psp_repartition_volumes_par_pays_bin_top10"
          :translations="translations"
          :project="$store.getters.merchantId"
          :external-filters-values="globalFilters"
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="auto"
        />
      </v-col>
      <v-col cols="12" lg="6" md="12">
        <visualization
          card
          type="bar"
          query="psp_repartition_volumes_par_pays_bin_top10"
          :translations="translations"
          :project="$store.getters.merchantId"
          :title="$t('distributionByCountryOfIssueOfTheCard')"
          :options="{
            noData: {
              text: $t('emptyData'),
            },
            xAxes: [$t('country')],
            yAxes: ['Volume'],
            colors: paletteChart,
          }"
          :external-filters-values="globalFilters"
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="auto"
        />
      </v-col>
    </v-row>

    <div class="divider-title">
      {{ $t('distributionOfTransactionsByAuthenticationStatus') }}
    </div>
    <v-row>
      <v-col cols="12" lg="6" md="12">
        <visualization
          card
          type="table"
          :title="$t('distributionOfTransactionsByAuthenticationStatus')"
          query="psp_transactions_classees_par_holder_authent_statut_tableau"
          :translations="translations"
          :project="$store.getters.merchantId"
          :external-filters-values="globalFilters"
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="auto"
        />
      </v-col>
      <v-col cols="12" lg="6" md="12">
        <visualization
          card
          type="pie"
          query="psp_transactions_classees_par_holder_authent_status_graph"
          :project="$store.getters.merchantId"
          :title="$t('distributionOfTransactionsByAuthenticationStatus')"
          :options="{
            noData: {
              text: $t('emptyData'),
            },
            xAxes: [`Statut d'authentification`],
            yAxes: ['Transaction'],
            colors: paletteChart,
          }"
          :external-filters-values="globalFilters"
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="auto"
        />
      </v-col>
    </v-row>
    <div class="divider-title">
      {{ $t('distributionOfTransactionsByChallengeMode') }}
    </div>
    <v-row>
      <v-col cols="12" lg="6" md="12">
        <visualization
          card
          type="table"
          :title="$t('distributionOfTransactionsByChallengeMode')"
          query="psp_repartition_des_transactions_par_challenge_mode_tableau"
          :translations="translations"
          :project="$store.getters.merchantId"
          :external-filters-values="globalFilters"
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="auto"
        />
      </v-col>
      <v-col cols="12" lg="6" md="12">
        <visualization
          card
          type="pie"
          query="psp_repartition_des_transactions_par_challenge_mode"
          :project="$store.getters.merchantId"
          :title="$t('distributionOfTransactionsByChallengeMode')"
          :options="{
            noData: {
              text: $t('emptyData'),
            },
            xAxes: ['Challenge mode'],
            yAxes: ['Transaction'],
            colors: paletteChart,
          }"
          :external-filters-values="globalFilters"
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="auto"
        />
      </v-col>
    </v-row>
    <div class="divider-title">
      {{ $t('distributionOfTransactionsByResponseCodeDescription') }}
    </div>
    <v-row>
      <v-col cols="12" lg="6" md="12">
        <visualization
          card
          type="table"
          :title="$t('distributionOfTransactionsByResponseCodeDescription')"
          query="psp_repartition_des_transactions_par_response_code_description"
          :translations="translations"
          :project="$store.getters.merchantId"
          :external-filters-values="globalFilters"
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="auto"
        />
      </v-col>
      <v-col cols="12" lg="6" md="12">
        <visualization
          card
          type="pie"
          query="psp_repartition_des_transactions_par_response_code_description"
          :project="$store.getters.merchantId"
          :title="$t('distributionOfTransactionsByResponseCodeDescription')"
          :options="{
            noData: {
              text: $t('emptyData'),
            },
            xAxes: ['Response code description'],
            yAxes: ['Volume'],
            colors: paletteChart,
          }"
          :external-filters-values="globalFilters"
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="auto"
        />
      </v-col>
    </v-row>
    <div v-if="$store?.getters?.merchantName == 'AIR_TAHITI'">
      <div class="divider-title">
        {{ $t('distributionOfAnomalies') }}
      </div>
      <v-row>
        <v-col cols="12" lg="6" md="12">
          <visualization
            card
            type="table"
            :title="$t('distributionOfAnomaliesDescription')"
            query="psp_repartition_des_anomalies"
            :translations="translations"
            :project="$store.getters.merchantId"
            :external-filters-values="anomaliesFilters"
            :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
            width="100%"
            height="auto"
          />
        </v-col>
        <v-col cols="12" lg="6" md="12">
          <visualization
            card
            type="pie"
            query="psp_repartition_des_anomalies"
            :project="$store.getters.merchantId"
            :title="$t('distributionOfAnomaliesDescription')"
            :options="{
              noData: {
                text: $t('emptyData'),
              },
              xAxes: ['Type d\'anomalie'],
              yAxes: ['Volume'],
              colors: paletteChart,
            }"
            :external-filters-values="anomaliesFilters"
            :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
            width="100%"
            height="auto"
          />
        </v-col>
      </v-row>
    </div>

    <FiltersDrawer
      :filters="filters()"
      @update="
        (v) => {
          globalFilters = v
        }
      "
      :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
    />
  </v-container>
</template>

<script>
import query from '@/services/QueryService.js'
import { camelCase, deburr, map, groupBy } from 'lodash'
import TopHeader from './../components/base/TopHeader'
import FiltersDrawer from '@/components/inputs/FiltersDrawer.vue'
import { palette1 } from '@/utils/theme-chart.js'
export default {
  name: 'Psp',
  components: {
    FiltersDrawer,
    CoreAppBar: () => import('@/components/layouts/core/AppBar'),
  },
  data() {
    return {
      globalFilters: [],
      paletteChart: palette1,
      showSelectCurrency: false,
      currencies: [],
      currency: null,
    }
  },
  mounted() {
    this.getCurrencies()
  },
  computed: {
    specificFilters() {
      let filters = this.filters()
      const globalFilters = this.globalFilters.reduce((cumul, curr) => {
        const found = filters.find((f) => f.name == curr.column)
        if (found) cumul[deburr(camelCase(found.label))] = curr
        return cumul
      }, {})
      const { periode, ...restOfFilters } = globalFilters
      return map(restOfFilters, (o) => o)
    },
    anomaliesFilters() {
      let filters = this.filters()
      const globalFilters = this.globalFilters.reduce((cumul, curr) => {
        const found = filters.find((f) => f.name == curr.column)
        if (found) cumul[deburr(camelCase(found.label))] = curr
        return cumul
      }, {})
      const {
        moPType,
        authentStatus,
        countryBin,
        brand,
        country,
        salesChannel,
        ...restOfFilters
      } = globalFilters
      console.log(restOfFilters)
      return map(restOfFilters, (o) => o)
    },
    settlementCurrencyFilters() {
      let newCurrency = []

      const settlement_curr_filter = {
        column: 'currency_alphabetical_code',
        columnWrapper: undefined,
        operator: 'IN',
        value: [this.currency],
      }

      if (this.currency && this.showSelectCurrency) {
        newCurrency.push(...this.globalFilters, settlement_curr_filter)
      }

      return newCurrency
    },
    translations() {
      let dict = {}
      dict[this.$t('methodPaymentType', 'fr')] = this.$t('methodPaymentType')
      dict[this.$t('authentificationStatus', 'fr')] = this.$t(
        'authentificationStatus'
      )
      dict[this.$t('transactionVolumeInAcceptance', 'fr')] = this.$t(
        'transactionVolumeInAcceptance'
      )
      dict[this.$t('currencyVolumeAccepted', 'fr')] = this.$t(
        'currencyVolumeAccepted'
      )
      dict[this.$t('acceptanceRateRaw', 'fr')] = this.$t('acceptanceRateRaw')
      dict[this.$t('acceptanceRateNetWithoutDup', 'fr')] = this.$t(
        'acceptanceRateNetWithoutDup'
      )
      dict[this.$t('authorizationRate', 'fr')] = this.$t('authorizationRate')
      dict[this.$t('methodPaymentType', 'fr')] = this.$t('methodPaymentType')
      dict[this.$t('salesChannel', 'fr')] = this.$t('salesChannel')
      dict[this.$t('country', 'fr')] = this.$t('country')
      dict[this.$t('brand', 'fr')] = this.$t('brand')
      dict[this.$t('rate', 'fr')] = this.$t('rate')
      dict[this.$t('clues', 'fr')] = this.$t('clues')
      dict[this.$t('transactionVolumeInAcceptance', 'fr')] = this.$t(
        'transactionVolumeInAcceptance'
      )
      dict[this.$t('volumeTransactions', 'fr')] = this.$t('volumeTransactions')
      dict[this.$t('anomalyType', 'fr')] = this.$t('anomalyType')
      return dict
    },
  },
  methods: {
    /*
      Filter model is like so :
      {
        name: "card_brand", //target column
        type: "list", //filter type (list, text, number, date, year, month, daterange)
        multiple: true, //this is useless for now
        label: "Marque de la carte", //Input placeholder
        values: { //Values can be an Array of basic values (manually set) or an Object that defines where it is going to get data from (from project x, dataset y , column z)
          project: this.$store.getters.merchantId,
          column: "card_brand",
          dataset: "consolidation_psp_ram"
        }
      }

    */
    adaptDatasetName(name) {
      return `consolidation_${name}_${this.lo
        .snakeCase(this.$store.getters.merchantName)
        .replace('_1', '1') // ToDo: This is an ugly fix. To improve
        .replace('_2', '2')
        .replace('_3', '3')
        .replace('_4', '4')
        .replace('_5', '5')
        .replace('_6', '6')
        .replace('_7', '7')
        .replace('_8', '8')
        .replace('_9', '9')}`
    },
    async print() {
      window.print()
    },
    filters() {
      return [
        {
          name: 'mp_type',
          type: 'list',
          multiple: true,
          label: this.$t('mopType', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'mp_type',
            dataset: this.adaptDatasetName('psp'),
            conditionName: 'conditions',
          },
        },
        {
          name: 'office_id',
          type: 'list',
          multiple: true,
          label: this.$t('officeId', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'office_id',
            dataset: this.adaptDatasetName('psp'),
            conditionName: 'conditions',
          },
        },
        {
          name: 'holder_authent_status',
          type: 'list',
          multiple: true,
          label: 'Authent status',
          values: {
            project: this.$store.getters.merchantId,
            column: 'holder_authent_status',
            dataset: this.adaptDatasetName('psp'),
            conditionName: 'conditions',
          },
        },
        {
          name: 'cccty',
          type: 'list',
          multiple: true,
          label: this.$t('country', 'en') + 'BIN',
          values: {
            project: this.$store.getters.merchantId,
            column: 'cccty',
            dataset: this.adaptDatasetName('psp'),
            conditionName: 'conditions',
          },
        },
        {
          name: 'brand',
          type: 'list',
          multiple: true,
          label: this.$t('brand', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'brand',
            dataset: this.adaptDatasetName('psp'),
            conditionName: 'conditions',
          },
        },
        {
          name: 'country',
          type: 'country',
          multiple: true,
          label: this.$t('country', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'country',
            dataset: this.adaptDatasetName('psp'),
            conditionName: 'conditions',
          },
        },
        {
          name: 'sales_channel',
          type: 'list',
          multiple: true,
          label: this.$t('salesChannel', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'sales_channel',
            dataset: this.adaptDatasetName('psp'),
            conditionName: 'conditions',
          },
        },
        {
          name: 'organism',
          type: 'list',
          multiple: true,
          label: this.$t('pspName', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'organism',
            dataset: this.adaptDatasetName('psp'),
            conditionName: 'conditions',
          },
        },
        {
          name: 'full_date::date',
          type: 'daterange',
          multiple: true,
          label: this.$t('period', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'full_date::date',
            dataset: this.adaptDatasetName('psp'),
          },
          value: '',
        },
      ].reverse()
    },
    thousandsSeparator(v) {
      return v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    percentFormatter(v) {
      return String(v) + ' %'
    },
    currencyFormatter(v) {
      return this.thousandsSeparator(v) + ' €'
    },
    handleToggleFilters(v) {
      this.$store.commit('setFiltersDrawerExpanded', v)
    },

    getCurrencies() {
      const querySettlementCurrency = `SELECT DISTINCT currency_alphabetical_code as "values" FROM consolidation_psp_${this.$store.getters.merchantName} WHERE currency_alphabetical_code <> '';`

      query
        .runAnon(
          this,
          this.$store.getters.merchantId,
          querySettlementCurrency,
          null
        )
        .then((r) => {
          r.data.rows.map((item) => {
            this.currencies.push(item.values)
          })
        })
    },
    handleSelectCurrency(v) {
      this.currency = v
    },
    handleShowCurrency(v) {
      this.showSelectCurrency = v
      this.currency = null
    },
  },
}
</script>

<style scoped>
.fixed {
  z-index: 999;
  position: sticky;
  position: -webkit-sticky;
  top: 88px;
}

/* Groupe Filter */
.hmd-query-parameter-input .el-input--mini .el-input__inner::placeholder,
.hmd-query-parameter-input .el-select .el-input .el-select__caret,
.hmd-query-parameter-input .el-date-editor .el-range__icon,
.hmd-query-parameter-input .el-date-editor .el-range__close-icon,
.hmd-query-parameter-input .el-date-editor .el-date-editor .el-range-input,
.hmd-query-parameter-input .el-date-editor .el-range-separator {
  color: white;
}

.hmd-query-parameter-input .el-input--mini .el-input__inner,
.hmd-query-parameter-input .el-range-editor--mini.el-input__inner {
  background-color: #2d62ed;
  border: none;
  /* height: 30px;  */
}

.small-header .hmd-visualization-header {
  padding: 4px 0px 2px 20px !important;
}

.el-table .caret-wrapper {
  display: none !important;
  cursor: pointer;
}
</style>

<style>
table.hmd-visualization-table td {
  word-break: break-word;
}
</style>
