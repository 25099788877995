<template>
  <v-container
    class="dash pl-10"
    :style="{
      'padding-right': $store.state.filtersDrawerExpanded
        ? !$vuetify.breakpoint.smAndDown
          ? '320px'
          : '20px'
        : '12px',
    }"
    fluid
  >
    <core-app-bar />
    <v-row no-gutters>
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          dark
          color="blue-grey lighten-2"
          class="d-print-none text-capitalize"
          @click="handleToggleFilters(true)"
        >
          <v-icon> mdi-filter-outline</v-icon>
          {{ $t('filters') }}
        </v-btn>
        <v-btn
          color="primary"
          class="d-print-none text-capitalize ml-2"
          @click="print"
        >
          <v-icon left>mdi-printer-outline</v-icon>
          {{ $t('print') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-overlay
      :value="pageLoading"
      class="text-center"
      :z-index="10000"
      :opacity="0.95"
      color="white"
    >
      <v-progress-circular
        indeterminate
        color="primary"
        size="64"
      ></v-progress-circular>
      <div class="my-5 primary--text">
        {{ $t('dataLoading') }}
      </div>
      <v-btn text @click="pageLoading = false" small color="primary">
        <v-icon left>mdi-close</v-icon>
        {{ $t('close') }}</v-btn
      >
    </v-overlay>
    <v-row
      v-if="isRangeDateSet"
      class="mt-4 counters-row"
      :style="{ top: marginBlocFilter }"
    >
      <v-col cols="12" sm="4" md="2" class="px-2 py-1">
        <visualization
          card
          type="counter"
          class="header-block too-big-header-block selected-period-counter"
          :external-value="dateFilterValue"
          :options="{
            noData: {
              text: this.$t('filters'),
            },
            align: 'left',
            column: 'result',
            title: {
              value: this.$t('selectedPeriod'),
            },
          }"
          width="100%"
          height="100%"
        ></visualization>
      </v-col>
      <v-col cols="12" sm="4" md="2" class="px-2 py-1">
        <visualization
          card
          type="counter"
          class="header-block"
          query="header_volume_net_encaisse"
          :project="$store.getters.merchantId"
          :options="{
            noData: {
              text: this.$t('emptyData'),
            },
            align: 'left',
            column: 'Volume encaissé (net)',
            title: {
              value: this.$t('collectedVolume'),
            },
          }"
          :external-filters-values="globalFilters"
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="100%"
        ></visualization>
      </v-col>
      <v-col cols="12" sm="4" md="2" class="px-2 py-1">
        <visualization
          card
          type="counter"
          class="header-block"
          query="acquereur_header_volume_transaction"
          :project="$store.getters.merchantId"
          :options="{
            noData: {
              text: this.$t('emptyData'),
            },
            align: 'left',
            column: 'Volume de transactions',
            title: {
              value: this.$t('numberOfTransactions'),
            },
          }"
          :external-filters-values="globalFilters"
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="100%"
        ></visualization>
      </v-col>
      <v-col cols="12" sm="4" md="2" class="px-2 py-1">
        <visualization
          card
          type="counter"
          class="header-block"
          query="header_acquereur_commission"
          :project="$store.getters.merchantId"
          :options="{
            noData: {
              text: this.$t('emptyData'),
            },
            align: 'left',
            column: 'Total Commission',
            title: {
              value: this.$t('commissionsPaid'),
            },
          }"
          :external-filters-values="globalFilters"
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="100%"
        ></visualization>
      </v-col>
      <v-col cols="12" sm="4" md="2" class="px-2 py-1">
        <visualization
          v-show="!impayesError"
          card
          type="counter"
          class="header-block"
          ref="impayes-block"
          query="header_somme_impayes"
          :project="$store.getters.merchantId"
          :options="{
            noData: {
              text: this.$t('emptyData'),
            },
            align: 'left',
            column: 'Total',
            title: {
              value: this.$t('unpaid'),
            },
            value: { format: thousandsSeparator, suffix: '€' },
          }"
          :external-filters-values="specificFilters"
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="100%"
        ></visualization>
      </v-col>
      <v-col cols="12" sm="4" md="2" class="px-2 py-1">
        <visualization
          card
          type="counter"
          class="header-block too-big-header-block"
          ref="avoirs-block"
          query="header_avoirs"
          :project="$store.getters.merchantId"
          :options="{
            noData: {
              text: this.$t('emptyData'),
            },
            align: 'center',
            column: 'Refund',
            title: {
              value: this.$t('creditOrRedunds'),
              align: 'center',
            },
          }"
          :external-filters-values="globalFilters"
          :forcedUser="$store.state.preview ? $store.state.preview.pk : null"
          width="100%"
          height="100%"
        ></visualization>
      </v-col>
    </v-row>
    <v-row v-if="isRangeDateSet">
      <v-col>
        <v-col cols="6" class="py-0">
          <div class="divider-title">{{ $t('acceptanceTotal') }}</div>
        </v-col>
        <v-col lg="6" class="py-0">
          <v-row>
            <v-col lg="6">
              <v-switch
                :label="$t('displaySettlementToggleLabel')"
                v-model="showSelectCurrency"
                v-on:change="handleShowCurrency"
              ></v-switch>
            </v-col>
            <v-col lg="6" v-if="showSelectCurrency">
              <v-select
                :label="$t('currency')"
                v-model="currency"
                :items="currencies"
                v-on:change="handleSelectCurrency"
              ></v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-row
          :class="{
            'd-flex': showSelectCurrency,
            'align-center': showSelectCurrency,
          }"
        >
          <v-col lg="6" md="12">
            <visualization
              card
              class="card-acceptance main-table"
              type="table"
              query="acquereurs_acceptation_carte"
              :translations="translations"
              :project="$store.getters.merchantId"
              :title="$t('cardAcceptance')"
              :external-filters-values="globalFilters"
              :forcedUser="
                $store.state.preview ? $store.state.preview.pk : null
              "
              width="100%"
              height="100%"
            ></visualization>
          </v-col>
          <v-col lg="6" md="12">
            <div
              class="d-flex justify-center flex-column align-center"
              v-if="showSelectCurrency && !currency"
            >
              <v-icon size="100" class="mb-4 grey--text text--lighten-1">
                mdi-swap-horizontal
              </v-icon>
              <span class="text-center text-h5 grey--text text--lighten-1">
                {{ $t('chooseCurrencyFilter') }}</span
              >
            </div>

            <visualization
              card
              class="card-acceptance main-table"
              type="table"
              query="acquereurs_acceptation_carte_settlement"
              :external-filters-values="settlementCurrencyFilters"
              :translations="translations"
              :project="$store.getters.merchantId"
              :title="$t('settlementAcceptance')"
              :forcedUser="
                $store.state.preview ? $store.state.preview.pk : null
              "
              width="100%"
              height="100%"
              v-if="showSelectCurrency && currency"
            ></visualization>
            <v-row no-gutters class="pt-0" v-if="!showSelectCurrency">
              <v-col cols="12" class="pt-0">
                <visualization
                  card
                  type="pie"
                  query="new_acquereurs_commission_moyenne"
                  :translations="translations"
                  :project="$store.getters.merchantId"
                  :title="$t('distributionByTypeOfCommission')"
                  :options="{
                    xAxes: ['type'],
                    yAxes: ['total_commission'],
                    colors: paletteChart,
                    tooltip: {
                      y: {
                        formatter: currencyFormatter,
                      },
                    },
                  }"
                  :external-filters-values="globalFilters"
                  :forcedUser="
                    $store.state.preview ? $store.state.preview.pk : null
                  "
                  width="100%"
                  height="auto"
                ></visualization>
              </v-col>
            </v-row>
            <v-row class="pt-0" v-if="!showSelectCurrency">
              <v-col cols="12" class="pt-0">
                <visualization
                  card
                  type="table"
                  query="acquereurs_volume_debit_credit"
                  :translations="translations"
                  :project="$store.getters.merchantId"
                  :title="$t('distributionByTypeOfTransaction')"
                  :external-filters-values="globalFilters"
                  :forcedUser="
                    $store.state.preview ? $store.state.preview.pk : null
                  "
                  width="100%"
                  height="auto"
                ></visualization>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-if="showSelectCurrency">
          <v-col lg="6" md="12" class="pt-0">
            <visualization
              card
              type="pie"
              query="new_acquereurs_commission_moyenne"
              :translations="translations"
              :project="$store.getters.merchantId"
              :title="$t('distributionByTypeOfCommission')"
              :options="{
                xAxes: ['type'],
                yAxes: ['total_commission'],
                colors: paletteChart,
                tooltip: {
                  y: {
                    formatter: currencyFormatter,
                  },
                },
              }"
              :external-filters-values="globalFilters"
              :forcedUser="
                $store.state.preview ? $store.state.preview.pk : null
              "
              width="100%"
              height="auto"
            ></visualization>
          </v-col>
          <v-col lg="6" md="12" class="pt-0">
            <visualization
              card
              type="table"
              query="acquereurs_volume_debit_credit"
              :translations="translations"
              :project="$store.getters.merchantId"
              :title="$t('distributionByTypeOfTransaction')"
              :external-filters-values="globalFilters"
              :forcedUser="
                $store.state.preview ? $store.state.preview.pk : null
              "
              width="100%"
              height="auto"
            ></visualization>
          </v-col>
        </v-row>

        <div class="divider-title">
          {{ $t('breakdownOfNetVolumeCollected') }}
        </div>
        <v-row>
          <v-col lg="6" md="12">
            <visualization
              card
              type="table"
              query="acquereurs_repartition_du_volume_net_encaisse_table"
              :translations="translations"
              :project="$store.getters.merchantId"
              :title="$t('breakdownOfNetVolumeCollectedDesciption')"
              :external-filters-values="globalFilters"
              :forcedUser="
                $store.state.preview ? $store.state.preview.pk : null
              "
              width="100%"
              height="auto"
            />
          </v-col>
          <v-col lg="6" md="12">
            <visualization
              card
              type="pie"
              query="acquereurs_repartition_du_volume_net_encaisse_pie"
              :project="$store.getters.merchantId"
              :title="$t('breakdownOfNetVolumeCollectedDesciption')"
              :options="{
                xAxes: ['Provider'],
                yAxes: ['Montant (net)'],
                colors: paletteChart,
                dataLabels: {
                  formatter: percentFormatter,
                },
              }"
              :external-filters-values="globalFilters"
              :forcedUser="
                $store.state.preview ? $store.state.preview.pk : null
              "
              width="100%"
              height="auto"
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="divider-title">
              {{ $t('consumerOrCorporateAcceptance') }}
            </div>
          </v-col>
          <v-col cols="12">
            <v-row class="pt-0">
              <v-col lg="8" md="12" class="pt-0">
                <v-row class="pt-0">
                  <v-col cols="12" lg="6" class="pt-0">
                    <visualization
                      card
                      class="card-acceptance secondary-table"
                      type="table"
                      query="acquereurs_acceptation_carte_consumer"
                      :translations="translations"
                      :project="$store.getters.merchantId"
                      :title="$t('consumerCardAcceptance')"
                      :external-filters-values="globalFilters"
                      :forcedUser="
                        $store.state.preview ? $store.state.preview.pk : null
                      "
                      width="100%"
                      height="auto"
                    ></visualization>
                  </v-col>
                  <v-col cols="12" lg="6" class="pt-0">
                    <visualization
                      card
                      class="card-acceptance secondary-table"
                      type="table"
                      query="acquereurs_acceptation_carte_corporate"
                      :translations="translations"
                      :project="$store.getters.merchantId"
                      :title="$t('corporateCardAcceptance')"
                      :external-filters-values="globalFilters"
                      :forcedUser="
                        $store.state.preview ? $store.state.preview.pk : null
                      "
                      width="100%"
                    ></visualization>
                  </v-col>
                </v-row>
              </v-col>
              <v-col lg="4" md="12" class="pt-0">
                <v-row class="pt-0">
                  <v-col cols="12" class="pt-0">
                    <visualization
                      card
                      class="small-header"
                      type="pie"
                      query="acquereurs_repartition_du_chiffre_daffaire"
                      :translations="translations"
                      :project="$store.getters.merchantId"
                      :title="$t('distributionOfRevenues')"
                      :options="{
                        xAxes: ['Type de carte'],
                        yAxes: ['volume_affaires_net'],
                        colors: paletteChart,
                        tooltip: {
                          y: {
                            formatter: currencyFormatter,
                          },
                        },
                        plotOptions: {
                          pie: {
                            customScale: 0.8,
                          },
                        },
                      }"
                      :external-filters-values="globalFilters"
                      :forcedUser="
                        $store.state.preview ? $store.state.preview.pk : null
                      "
                      width="100%"
                      height="auto"
                    ></visualization>
                  </v-col>
                </v-row>
                <v-row class="pt-0">
                  <v-col cols="12" class="pt-0">
                    <visualization
                      card
                      class="small-header"
                      type="pie"
                      query="acquereurs_repartition_des_commisions"
                      :translations="translations"
                      :project="$store.getters.merchantId"
                      :title="$t('distributionOfCommissions')"
                      :options="{
                        xAxes: ['Type de carte'],
                        yAxes: ['Total commission'],
                        colors: paletteChart,
                        tooltip: {
                          y: {
                            formatter: currencyFormatter,
                          },
                        },
                        plotOptions: {
                          pie: {
                            customScale: 0.8,
                          },
                        },
                      }"
                      :external-filters-values="globalFilters"
                      :forcedUser="
                        $store.state.preview ? $store.state.preview.pk : null
                      "
                      width="100%"
                      height="auto"
                    ></visualization>
                  </v-col>
                </v-row>

                <v-row class="pt-0"> </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <div class="divider-title">
              {{ $t('vccImpact') }}
            </div>
          </v-col>
          <v-col cols="12">
            <v-row class="pt-0">
              <v-col cols="12" lg="6" class="pt-0">
                <visualization
                  card
                  class="card-acceptance secondary-table"
                  type="table"
                  query="acquereurs_acceptation_carte_vcc"
                  :translations="translations"
                  :project="$store.getters.merchantId"
                  :title="$t('vccAcceptance')"
                  :external-filters-values="globalFilters"
                  :forcedUser="
                    $store.state.preview ? $store.state.preview.pk : null
                  "
                  width="100%"
                  height="auto"
                ></visualization>
              </v-col>
              <v-col cols="12" lg="6" class="pt-0">
                <v-row>
                  <v-col cols="12">
                    <visualization
                      card
                      type="counter"
                      query="acquereurs_part_des_vcc_dans_corporate"
                      :project="$store.getters.merchantId"
                      :options="{
                        noData: {
                          text: $t('emptyData'),
                        },
                        align: 'center',
                        column: 'round',
                        title: {
                          value: $t('vccCommissions'),
                        },
                        value: { suffix: ' %' },
                      }"
                      :external-filters-values="globalFilters"
                      :forcedUser="
                        $store.state.preview ? $store.state.preview.pk : null
                      "
                      width="100%"
                      height="auto"
                    ></visualization>
                  </v-col>

                  <v-col cols="12" class="pt-0">
                    <visualization
                      card
                      type="counter"
                      query="acquereurs_part_commissions_vcc_dans_corporate"
                      :project="$store.getters.merchantId"
                      :options="{
                        noData: {
                          text: $t('emptyData'),
                        },
                        align: 'center',
                        column: 'round',
                        title: {
                          value: $t('vccSales'),
                        },
                        value: { suffix: ' %' },
                      }"
                      :external-filters-values="globalFilters"
                      :forcedUser="
                        $store.state.preview ? $store.state.preview.pk : null
                      "
                      width="100%"
                      height="auto"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div class="divider-title">{{ $t('acceptanceBySalesChannel') }}</div>
        <v-row>
          <v-col lg="4" md="12">
            <visualization
              card
              type="table"
              query="acquereurs_acceptation_par_canal_de_vente"
              :translations="translations"
              :project="$store.getters.merchantId"
              :title="$t('acceptanceBySalesChannel')"
              :external-filters-values="globalFilters"
              :forcedUser="
                $store.state.preview ? $store.state.preview.pk : null
              "
              width="100%"
              height="auto"
            />
          </v-col>
          <v-col lg="4" md="12">
            <visualization
              card
              type="pie"
              query="acquereurs_repartition_du_chiffre_d_affaire"
              :project="$store.getters.merchantId"
              :title="$t('distributionOfRevenues')"
              :options="{
                xAxes: ['sales_channel'],
                yAxes: ['round'],
                colors: paletteChart,
                tooltip: {
                  y: {
                    formatter: currencyFormatter,
                  },
                },
              }"
              :external-filters-values="globalFilters"
              :forcedUser="
                $store.state.preview ? $store.state.preview.pk : null
              "
              width="100%"
              height="auto"
            />
          </v-col>
          <v-col lg="4" md="12">
            <visualization
              card
              type="bar"
              query="acquereurs_commision_moyenne_par_canal"
              :translations="translations"
              :project="$store.getters.merchantId"
              :title="$t('averageCommissionPerChannel')"
              :options="{
                xAxes: ['sales_channel'],
                yAxes: [$t('percent')],
                colors: paletteChart,
                dataLabels: {
                  formatter: percentFormatter,
                },
              }"
              :external-filters-values="globalFilters"
              :forcedUser="
                $store.state.preview ? $store.state.preview.pk : null
              "
              width="100%"
              height="auto"
            />
          </v-col>
        </v-row>
        <div class="divider-title">
          {{ $t('acceptanceByCountry') }} (top 10)
        </div>
        <v-row>
          <v-col lg="6" md="12" class="pt-0">
            <visualization
              card
              type="table"
              query="acquereurs_acceptation_par_pays"
              :translations="translations"
              :project="$store.getters.merchantId"
              :title="$t('acceptanceByCountry')"
              :external-filters-values="globalFilters"
              :forcedUser="
                $store.state.preview ? $store.state.preview.pk : null
              "
              width="100%"
              height="auto"
            />
          </v-col>
          <v-col lg="6" md="12">
            <v-row>
              <v-col class="pt-0" cols="12">
                <visualization
                  card
                  type="bar"
                  query="acquereurs_repartition_du_chiffre_daffaire_par_pays"
                  :translations="translations"
                  :project="$store.getters.merchantId"
                  :title="$t('distributionOfRevenuesByCountry')"
                  :options="{
                    noData: {
                      text: $t('emptyData'),
                    },
                    colors: paletteChart,
                    xAxes: [$t('country')],
                    yAxes: [$t('revenues')],
                    dataLabels: {
                      formatter: currencyFormatter,
                      style: {
                        colors: ['#000'],
                      },
                    },
                  }"
                  :external-filters-values="globalFilters"
                  :forcedUser="
                    $store.state.preview ? $store.state.preview.pk : null
                  "
                  width="100%"
                  height="auto"
                />
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pt-0" cols="12">
                <visualization
                  card
                  type="bar"
                  query="acquereurs_repartition_du_chiffre_daffaire_par_pays"
                  :translations="translations"
                  :project="$store.getters.merchantId"
                  :title="$t('averageCommissionRatePerCountry')"
                  :options="{
                    noData: {
                      text: $t('emptyData'),
                    },
                    colors: paletteChart,
                    xAxes: [$t('country')],
                    yAxes: [$t('averageCommission')],
                    dataLabels: {
                      formatter: percentFormatter,
                      style: {
                        colors: ['#000'],
                      },
                    },
                  }"
                  :external-filters-values="globalFilters"
                  :forcedUser="
                    $store.state.preview ? $store.state.preview.pk : null
                  "
                  width="100%"
                  height="auto"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <div class="divider-title">
          {{ $t('distributionOfVolumesPerCountry') }}(Top 10)
        </div>
        <v-row>
          <v-col cols="12" lg="6" md="12">
            <visualization
              card
              type="table"
              :title="$t('distributionByCountryOfIssueOfTheCard')"
              query="acquereurs_repartition_volumes_par_pays_bin_top10"
              :translations="translations"
              :project="$store.getters.merchantId"
              :external-filters-values="globalFilters"
              :forcedUser="
                $store.state.preview ? $store.state.preview.pk : null
              "
              width="100%"
              height="auto"
            />
          </v-col>
          <v-col cols="12" lg="6" md="12">
            <visualization
              card
              type="bar"
              query="acquereurs_repartition_volumes_par_pays_bin_top10"
              :translations="translations"
              :project="$store.getters.merchantId"
              :title="$t('distributionByCountryOfIssueOfTheCard')"
              :options="{
                xAxes: [$t('binCountry')],
                yAxes: ['Volume'],
                colors: paletteChart,
                dataLabels: {
                  style: {
                    colors: ['#000'],
                  },
                },
              }"
              :external-filters-values="globalFilters"
              :forcedUser="
                $store.state.preview ? $store.state.preview.pk : null
              "
              width="100%"
              height="auto"
            />
          </v-col>
        </v-row>
        <div class="divider-title">{{ $t('acceptanceByNetwork') }}</div>
        <v-row>
          <v-col lg="6" md="12">
            <visualization
              card
              type="table"
              query="acquereurs_acceptation_par_brand_tableau"
              :translations="translations"
              :project="$store.getters.merchantId"
              :title="$t('acceptanceByNetwork')"
              :external-filters-values="globalFilters"
              :forcedUser="
                $store.state.preview ? $store.state.preview.pk : null
              "
              width="100%"
              height="auto"
            />
          </v-col>
          <v-col lg="6" md="12">
            <visualization
              card
              type="pie"
              query="acquereurs_acceptation_par_brand_graph"
              :project="$store.getters.merchantId"
              :title="$t('distributionOfRevenuesByNetwork')"
              :options="{
                xAxes: ['Network'],
                yAxes: ['Volume d\'affaire (brut)'],
                colors: paletteChart,
                dataLabels: {
                  formatter: percentFormatter,
                },
              }"
              :external-filters-values="globalFilters"
              :forcedUser="
                $store.state.preview ? $store.state.preview.pk : null
              "
              width="100%"
              height="auto"
            />
          </v-col>
        </v-row>
        <div class="divider-title">{{ $t('readjustments') }}</div>
        <v-row>
          <v-col lg="6" md="12">
            <visualization
              card
              class="adjustments"
              type="table"
              query="acquereurs_reajustements"
              :translations="translations"
              :project="$store.getters.merchantId"
              :title="$t('readjustments')"
              :external-filters-values="globalFilters"
              :forcedUser="
                $store.state.preview ? $store.state.preview.pk : null
              "
              width="100%"
              height="auto"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <FiltersDrawer
      :filters="filters()"
      ref="viz-filters"
      @update="updateGlobalFilters"
    />
  </v-container>
</template>

<script>
import FiltersDrawer from '@/components/inputs/FiltersDrawer.vue'
import query from '@/services/QueryService.js'
import { palette1 } from '@/utils/theme-chart.js'
import { camelCase, deburr, map } from 'lodash'
import moment from 'moment'

export default {
  name: 'Acquereurs',
  components: {
    FiltersDrawer,
    CoreAppBar: () => import('@/components/layouts/core/AppBar'),
  },
  data: () => {
    return {
      isRangeDateSet: false,
      globalFilters: [],
      impayesError: false,
      pageLoading: true,
      paletteChart: palette1,
      showSelectCurrency: false,
      currency: null,
      currencies: [],
    }
  },
  methods: {
    getCurrencies() {
      const querySettlementCurrency = `SELECT DISTINCT settlement_curr as "values" FROM consolidation_acquereurs_${this.$store.getters.merchantName} WHERE settlement_curr <> '';`

      query
        .runAnon(
          this,
          this.$store.getters.merchantId,
          querySettlementCurrency,
          null
        )
        .then((r) => {
          r.data.rows.map((item) => {
            this.currencies.push(item.values)
          })
        })
    },
    handleShowCurrency(v) {
      this.showSelectCurrency = v
      this.currency = null
    },
    handleSelectCurrency(v) {
      this.currency = v
    },
    async print() {
      window.print()
    },
    handleToggleFilters(v) {
      this.$store.commit('setFiltersDrawerExpanded', v)
    },
    adaptDatasetName(name) {
      return `consolidation_${name}_${this.lo
        .snakeCase(this.$store.getters.merchantName)
        .replace('_1', '1') // ToDo: This is an ugly fix. To improve
        .replace('_2', '2')
        .replace('_3', '3')
        .replace('_4', '4')
        .replace('_5', '5')
        .replace('_6', '6')
        .replace('_7', '7')
        .replace('_8', '8')
        .replace('_9', '9')}`
    },
    updateGlobalFilters(v) {
      this.globalFilters = v
    },
    filters() {
      return [
        {
          name: 'full_date',
          type: 'daterange',
          multiple: true,
          label: this.$t('month', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'month',
            dataset: this.adaptDatasetName('acquereurs'),
          },
          value: '',
        },
        {
          name: 'settlement_date::date',
          type: 'daterange',
          multiple: true,
          label: this.$t('settlementDate', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'settlement_date',
            dataset: this.adaptDatasetName('acquereurs'),
          },
          value: '',
        },
        {
          name: 'merchant_ref_2',
          type: 'list',
          multiple: true,
          label: this.$t('merchantRef', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'merchant_ref_2',
            dataset: this.adaptDatasetName('acquereurs'),
            conditionName: 'conditions',
          },
        },
        {
          name: 'merchant_ref_3',
          type: 'list',
          multiple: true,
          label: this.$t('merchantRef', 'en') + ' 2',
          values: {
            project: this.$store.getters.merchantId,
            column: 'merchant_ref_3',
            dataset: this.adaptDatasetName('acquereurs'),
            conditionName: 'conditions',
          },
        },
        {
          name: 'organism',
          type: 'checkbox',
          multiple: true,
          label: this.$t('serviceProvider', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'organism',
            dataset: this.adaptDatasetName('acquereurs'),
            conditionName: 'conditions',
          },
        },
        {
          name: 'country',
          type: 'country',
          multiple: true,
          label: this.$t('country', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'country',
            dataset: this.adaptDatasetName('acquereurs'),
            conditionName: 'conditions',
          },
        },
        {
          name: 'country_bin_code',
          type: 'country',
          multiple: true,
          label: this.$t('binCountry', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'country_bin_code',
            dataset: this.adaptDatasetName('acquereurs'),
            conditionName: 'conditions',
          },
        },
        {
          name: 'sales_channel',
          type: 'list',
          multiple: true,
          label: this.$t('salesChannel', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'sales_channel',
            dataset: this.adaptDatasetName('acquereurs'),
            conditionName: 'conditions',
          },
        },
        {
          name: 'distribution_channel',
          type: 'list',
          multiple: true,
          label: this.$t('distributionChannel', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'distribution_channel',
            dataset: this.adaptDatasetName('acquereurs'),
            conditionName: 'conditions',
          },
        },
        {
          name: 'brand',
          type: 'list',
          multiple: true,
          label: this.$t('brand', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'brand',
            dataset: this.adaptDatasetName('acquereurs'),
            conditionName: 'conditions',
          },
        },
        {
          name: 'card_type',
          type: 'list',
          multiple: true,
          label: this.$t('card', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'card_type',
            dataset: this.adaptDatasetName('acquereurs'),
            conditionName: 'conditions',
          },
        },
        {
          name: 'ic_type',
          type: 'list',
          multiple: true,
          label: this.$t('icType', 'fr'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'ic_type',
            dataset: this.adaptDatasetName('acquereurs'),
            conditionName: 'conditions',
          },
        },
        {
          name: 'sub_type',
          type: 'list',
          multiple: true,
          label: this.$t('subType', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'sub_type',
            dataset: this.adaptDatasetName('acquereurs'),
            conditionName: 'conditions',
          },
        },
        {
          name: 'card_usage',
          type: 'list',
          multiple: true,
          label: this.$t('cardUsage', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'card_usage',
            dataset: this.adaptDatasetName('acquereurs'),
            conditionName: 'conditions',
          },
        },
        {
          name: 'merchant_id',
          type: 'list',
          multiple: true,
          label: this.$t('merchantId', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'merchant_id::text',
            dataset: this.adaptDatasetName('acquereurs'),
            conditionName: 'conditions',
          },
        },
        {
          name: 'network',
          type: 'list',
          multiple: true,
          label: this.$t('network', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'network',
            dataset: this.adaptDatasetName('acquereurs'),
            conditionName: 'conditions',
          },
        },
        {
          name: 'status',
          type: 'list',
          multiple: true,
          label: this.$t('status', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'status',
            dataset: this.adaptDatasetName('acquereurs'),
            conditionName: 'conditions',
          },
        },
        {
          name: 'type',
          type: 'list',
          multiple: true,
          label: this.$t('mopType', 'en'),
          values: {
            project: this.$store.getters.merchantId,
            column: 'type',
            dataset: this.adaptDatasetName('acquereurs'),
            conditionName: 'conditions',
          },
        },
      ]
    },
    /*
      Filter model is like so :
      {
        name: "card_brand", //target column
        type: "list", //filter type (list, text, number, date, year, month, daterange)
        multiple: true, //this is useless for now
        label: "Marque de la carte", //Input placeholder
        values: { //Values can be an Array of basic values (manually set) or an Object that defines where it is going to get data from (from project x, dataset y , column z)
          project: this.$store.getters.merchantId,
          column: "card_brand",
          dataset: this.adaptDatasetName("psm)"
        }
      }

    */
    thousandsSeparator(v) {
      return v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    percentFormatter(v) {
      return String(v.toFixed(2)) + ' %'
    },
    currencyFormatter(v) {
      return this.thousandsSeparator(v) + ' €'
    },
    formatDate(date) {
      var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()

      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day

      return [year, month, day].join('-')
    },
  },
  computed: {
    translations() {
      let dict = {}
      dict[this.$t('amount', 'fr')] = this.$t('amount')
      dict[this.$t('amountNet', 'fr')] = this.$t('amountNet')
      dict[this.$t('rate', 'fr')] = this.$t('rate')
      dict[this.$t('collectedVolume', 'fr')] = this.$t('collectedVolume')
      dict[this.$t('businessVolumeRaw', 'fr')] = this.$t('businessVolumeRaw')
      dict[this.$t('totalCommission', 'fr')] = this.$t('totalCommission')
      dict[this.$t('acquirersCommission', 'fr')] = this.$t(
        'acquirersCommission'
      )
      dict[this.$t('networkCommission', 'fr')] = this.$t('networkCommission')
      dict[this.$t('interchangeCommission', 'fr')] = this.$t(
        'interchangeCommission'
      )
      dict[this.$t('dueCommission', 'fr')] = this.$t('dueCommission')
      dict[this.$t('paidCommissionMSC', 'fr')] = this.$t('paidCommissionMSC')
      dict[this.$t('readjustment', 'fr')] = this.$t('readjustment')
      dict[this.$t('volumeTransactions', 'fr')] = this.$t('volumeTransactions')
      dict[this.$t('transactionType', 'fr')] = this.$t('transactionType')
      dict[this.$t('transactionType', 'fr')] = this.$t('transactionType')
      dict[this.$t('rawRevenues', 'fr')] = this.$t('rawRevenues')
      dict[this.$t('trxVolume', 'fr')] = this.$t('trxVolume')
      dict[this.$t('debit', 'fr')] = this.$t('debit')
      dict[this.$t('credit', 'fr')] = this.$t('credit')
      dict[this.$t('country', 'fr')] = this.$t('country')
      dict[this.$t('averageCommission', 'fr')] = this.$t('averageCommission')
      dict[this.$t('binCountry', 'fr')] = this.$t('binCountry')
      dict[this.$t('ConsumerCard', 'fr')] = this.$t('ConsumerCard')
      dict[this.$t('CorporateCard', 'fr')] = this.$t('CorporateCard')
      dict[this.$t('percent', 'fr')] = this.$t('percent')
      dict[this.$t('revenues', 'fr')] = this.$t('revenues')
      dict[this.$t('serviceProvider', 'fr')] = this.$t('serviceProvider')
      return dict
    },
    settlementCurrencyFilters() {
      let newCurrency = []

      const settlement_curr_filter = {
        column: 'settlement_curr',
        columnWrapper: undefined,
        operator: 'IN',
        value: [this.currency],
      }

      if (this.currency && this.showSelectCurrency) {
        newCurrency.push(...this.globalFilters, settlement_curr_filter)
      }

      return newCurrency
    },
    specificFilters() {
      let filters = this.filters()
      const globalFilters = this.globalFilters.reduce((cumul, curr) => {
        const found = filters.find((f) => f.name == curr.column)
        if (found) cumul[deburr(camelCase(found.label))] = curr
        return cumul
      }, {})
      const {
        typeIc,
        cardType,
        network,
        subType,
        cardUsage,
        status,
        moPType,
        settlementDate,
        ...restOfFilters
      } = globalFilters
      return map(restOfFilters, (o) => o)
    },
    stickyFilters() {
      return this.scrollTop >= 370
    },
    marginBlocFilter: function () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xl':
          return '130px'
        default:
          return '170px'
      }
    },
    dateFilterValue() {
      let value = {
        columns: [{ name: 'result', type: 'string' }],
        rows: [{ result: '-' }],
      }

      let dateFilterValue = this.globalFilters.find((v) => {
        return v.column === 'full_date'
      })

      if (dateFilterValue && dateFilterValue.value) {
        if (dateFilterValue.value.length === 2) {
          // Deepcopying dateFilterValue so it doesn't modify true filter value
          dateFilterValue = JSON.parse(JSON.stringify(dateFilterValue))
          // We don't display '23:59:59' at the end of the second date
          dateFilterValue.value[1] = dateFilterValue.value[1].replace(
            ' 23:59:59',
            ''
          )
        }
        value['rows'][0]['result'] = dateFilterValue.value.join(' ')
      }

      return value
    },
  },
  mounted() {
    //Get the last processed month
    this.getCurrencies(),
      query
        .runWithProject(
          this,
          this.$store.getters.merchantId,
          'last_processed_month',
          []
        )
        .then((result) => {
          let value = result.data.rows[0]

          let start = moment(`${value.year}-${value.month}-01`)
          let end = moment(`${value.year}-${value.month}-01`).endOf('month')

          // let dayOffset = 24 * 60 * 60 * 1000;
          let rangeDate = [start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD')]
          this.$refs['viz-filters'].setFilterValue('full_date', rangeDate)
          setTimeout(() => {
            this.pageLoading = false
          }, 3000)
          this.isRangeDateSet = true
        })
  },
}
</script>

<style lang="scss">
/*.dash > .divider-title {*/
/*  margin-top: 20px;*/
/*  margin-bottom: 10px;*/
/*  font-family: "Roboto", Helvetica, sans-serif;*/
/*  color: black;*/
/*  font-size: 16px;*/
/*  font-weight: 300;*/
/*  text-transform: uppercase;*/
/*  text-align: left;*/
/*}*/
.counters-row .hmd-visualization-body .hmd-visualization-counter {
  border-radius: 8px !important;
}

.fixed {
  z-index: 999;
  position: sticky;
  position: -webkit-sticky;
  top: 88px;
  /* width: calc(100% - 260px); */
}

.fixed-block {
  z-index: 111;
  position: sticky;
  position: -webkit-sticky;
  top: 130px;
}

.hmd-visualization-title {
  color: #065280 !important;
  font-weight: 400 !important;
}

.el-table__body-wrapper.is-scrolling-none {
  height: auto !important;
}

table.hmd-visualization-table tbody td {
  word-break: normal !important;
}

.counters-row {
  .hmd-visualization-body {
    height: 100% !important;

    .hmd-visualization-counter {
      height: 100% !important;
      align-content: space-between;
    }
  }
}

/* Custom */
.card-acceptance .hmd-visualization-table th {
  font-size: 1em;
}

.card-acceptance .hmd-visualization-table td:first-child {
  color: #2d62ed;
}

.card-acceptance .hmd-visualization-table th:last-child {
  width: 140px;
}

.main-table .hmd-visualization-table tbody tr:nth-child(1),
.main-table .hmd-visualization-table tbody tr:nth-child(3),
.main-table .hmd-visualization-table tbody tr:nth-child(9) {
  background-color: #dae1f1;
  border-top: 1px solid #2d62ed;
}

.main-table .hmd-visualization-table tbody tr:nth-child(8) {
  background-color: #dae1f1;
}

.secondary-table .hmd-visualization-table tbody tr:nth-child(1),
.secondary-table .hmd-visualization-table tbody tr:nth-child(3),
.secondary-table .hmd-visualization-table tbody tr:nth-child(7) {
  background-color: #dae1f1;
  border-top: 1px solid #2d62ed;
}

.card-acceptance .hmd-visualization-table tbody tr:first-child {
  border-top: 1px solid #2d62ed;
}

.card-acceptance .hmd-visualization-table tbody tr:last-child {
  border-bottom: 1px solid #2d62ed;
}

.card-acceptance .hmd-visualization-table td:first-child {
  border-left: 1px solid #2d62ed;
}

.card-acceptance .hmd-visualization-table tbody td:last-child {
  border-right: 1px solid #2d62ed;
}

/* HMD Table Format */
.hmd-visualization-table td,
.hmd-visualization-table th {
  padding: 12px 25px !important;
}

.hmd-visualization-table .el-table__body-wrapper.is-scrolling-none {
  height: 100% !important;
}

table.hmd-visualization-table tbody td {
  word-break: break-word;
}

/* Align amount */
table.hmd-visualization-table tbody td:nth-child(2),
table.hmd-visualization-table thead th:nth-child(2) {
  text-align: right !important;
}

/* Align rates */
table.hmd-visualization-table tbody td:nth-child(3),
table.hmd-visualization-table thead th:nth-child(3) {
  text-align: center !important;
}

.hmd-visualization.hmd-visualization-card.header-block {
  /*background-color: #004671 !important;*/
  color: #004671 !important;
  /*  border: 1px solid #004671 !important;*/
}

.hmd-visualization.no-header .hmd-visualization-body .hmd-counter-container {
  min-height: 110px !important;
  padding: 12px 16px !important;
  text-align: center !important;
  border-radius: 8px !important;
}

.hmd-counter-flex {
  display: flex !important;
  align-items: center !important;
  flex: auto !important;
  flex-direction: column !important;
}

.hmd-counter-title {
  text-align: center !important;
  font-size: 0.9em !important;
  letter-spacing: 0 !important;
  font-weight: 300 !important;
  color: grey !important;
}

.hmd-counter-value {
  font-size: 1.2em !important;
  font-weight: 500 !important;
}

.selected-period-counter .hmd-counter-value {
  display: flex;
  flex-direction: column;
  text-align: center;
  font-size: 1em !important;
  justify-content: center;
  max-width: 85px;
}
</style>

<style scoped>
.header-block {
  height: 100%;
}

/* Numbers Block */

.hmd-visualization.hmd-visualization-card.header-block {
  background-color: #fff !important;
  border: 0px solid #e9e9e9 !important;
}
</style>

<style scoped>
.header-block {
  height: 100%;
}

/* Numbers Block */
.hmd-visualization.hmd-visualization-card.header-block {
  background-color: #fff !important;
  border: 0px solid #e9e9e9 !important;
}

.hmd-visualization.hmd-visualization-card {
  border: 1px solid #e9e9e9 !important;
}

/* Groupe Filter */

/* .hmd-query-parameter-input .el-input--mini .el-input__inner::placeholder,
.hmd-query-parameter-input .el-select .el-input .el-select__caret,
.hmd-query-parameter-input .el-date-editor .el-range__icon,
.hmd-query-parameter-input .el-date-editor .el-range__close-icon,
.hmd-query-parameter-input .el-date-editor .el-date-editor .el-range-input,
.hmd-query-parameter-input .el-date-editor .el-range-separator {
  color: white;
}
 */

.hmd-query-parameter-input {
  width: auto !important;
  padding: 10px 5px 0px 5px !important;
}

.hmd-query-parameter-input .el-input--mini .el-input__inner,
.hmd-query-parameter-input .el-range-editor--mini.el-input__inner {
  /* background-color: #004671; */
  /* border: none; */
  /* height: 36px; */
  margin: 5px auto;
  width: 100%;
}

.small-header .hmd-visualization-header {
  padding: 4px 0px 2px 20px !important;
}

.el-table .caret-wrapper {
  display: none !important;
  cursor: pointer;
}

.filter_error {
  font-size: 0.6em;
  color: orange;
}

.loader {
  height: 150px;
  width: 150px;
  border: solid #004671 3px;
  border-right: solid white 3px;
  border-radius: 100%;
  animation: 2s ease-out infinite turnaround;
  margin-bottom: 15%;
}

@keyframes turnaround {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
